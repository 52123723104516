import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import "./Style1.css";
import { useActiveLink } from "./ActiveLinkContext"; // Import the context hook

function Navbar1() {
  const { t } = useTranslation(); // Initialize t for translation
  const { activeLink, setActiveLinkGlobal } = useActiveLink(); // Access the active link state and update function from the context
  const [scrolled, setScrolled] = useState(false); // New state to track if the navbar is scrolled

  const handleNavLinkClick = (link) => {
    setActiveLinkGlobal(link);
  };

  // Function to handle closing the navbar
  const closeNavbar = () => {
    const navbar = document.getElementById("collapsibleNavbar");
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
    }
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-xl fixed-top bg-dark ${scrolled ? "scrolled" : ""
          } data-aos="fade-down`}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler me-2 p-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            style={{ backgroundColor: "#cf2d50", color: "white" }}
          >
            <i className="fas fa-stream" style={{ border: "none" }}></i>
          </button>

          <div
            className="collapse navbar-collapse justify-content-center"
            id="collapsibleNavbar"
          >
            <div className="d-flex w-100">
              <ul className="secondnav navbar-nav d-flex w-100 justify-content-between p-1 flex-column flex-md-row">
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <LanguageSwitcher className="me-5 pe-5" />
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/"
                    className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/");
                      closeNavbar();
                    }}
                  >
                    {t("முகப்பு")}
                  </NavLink>
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/history"
                    className={`nav-link ${activeLink === "/history" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/history");
                      closeNavbar();
                    }}
                  >
                    {t("வரலாறு")}
                  </NavLink>
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/about"
                    className={`nav-link ${activeLink === "/about" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/about");
                      closeNavbar();
                    }}
                  >
                    {t("பற்றி")}
                  </NavLink>
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/contact"
                    className={`nav-link ${activeLink === "/contact" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/contact");
                      closeNavbar();
                    }}
                  >
                    {t("தொடர்பு")}
                  </NavLink>
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/join"
                    className={`nav-link ${activeLink === "/join" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/join");
                      closeNavbar();
                    }}
                  >
                    {t("சேருங்கள்")}
                  </NavLink>
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/events"
                    className={`nav-link ${activeLink === "/events" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/events");
                      closeNavbar();
                    }}
                  >
                    {t("நிகழ்வுகள்")}
                  </NavLink>
                </li>
                <li className="nav-item flex-grow-1 d-flex justify-content-center">
                  <NavLink
                    exact
                    to="/donation"
                    className={`nav-link ${activeLink === "/donation" ? "active" : ""}`}
                    onClick={() => {
                      handleNavLinkClick("/donation");
                      closeNavbar();
                    }}
                  >
                    {t("நன்கொடை")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="fixed-bottom d-flex justify-content-end   mb-3">
        <div className="btn-whatsapp">
          <a href="https://wa.me/919942559847" target="_blank" rel="noopener noreferrer" className="nav-link text-black icon3">
            <button type="button" className="btn whatsapp-btn" style={{ borderColor: "green", borderWidth: "0px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="green" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
              </svg>              <span className="whatsapp-number ms-2">+91 9942559847</span>
            </button>
          </a>
        </div>
        <div className="btn-insta">
          <a href="https://www.instagram.com/ramasubramanian.g.3?igsh=ZW1sdGMwd3VxMWhq" target="_blank" rel="noopener noreferrer" className="nav-link text-black icon3">
            <button type="button" className="btn Insta-btn" style={{ borderColor: "pink", borderWidth: "0px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="url(#insta-gradient)" class="bi bi-instagram" viewBox="0 0 16 16">
                <defs>
                  <linearGradient id="insta-gradient" gradientTransform="rotate(45)">
                    <stop offset="0%" stop-color="#8a3ab9" />
                    <stop offset="25%" stop-color="#bc2a8d" />
                    <stop offset="50%" stop-color="#fccc63" />
                    <stop offset="75%" stop-color="#fbad50" />
                    <stop offset="100%" stop-color="#4c68d7" />
                  </linearGradient>
                </defs>
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
              </svg>

              <span className="insta-link ms-2">ramasubramanian.g.3</span>
            </button>
          </a>
        </div>
        <div className="btn-whatsapp">
          <a href="https://www.youtube.com/@ramasubramaniang3767" target="_blank" rel="noopener noreferrer" className="nav-link text-black icon3">
            <button type="button" className="btn Youtube-btn" style={{ borderColor: "red", borderWidth: "0px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red" class="bi bi-youtube" viewBox="0 0 16 16">
                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
              </svg>
              <span className="youtube-link ms-2">@ramasubramaniang3767</span>
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar1;
