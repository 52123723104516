import React, { createContext, useContext, useState } from 'react';

// Create a context to manage the active link state
const ActiveLinkContext = createContext();

// Custom hook to easily access the active link state and setter function
export const useActiveLink = () => useContext(ActiveLinkContext);

// Provider component to wrap the main content and provide access to the active link state
export const ActiveLinkProvider = ({ children }) => {
  // Initialize state for the active link, defaulting to '/'
  const [activeLink, setActiveLink] = useState('/');

  // Function to update the active link state globally
  const setActiveLinkGlobal = (link) => {
    setActiveLink(link);
  };

  return (
    // Provide the active link state and setter function to the context
    <ActiveLinkContext.Provider value={{ activeLink, setActiveLinkGlobal }}>
      {children} {/* Render the children components */}
    </ActiveLinkContext.Provider>
  );
};
