import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from './LanguageContext';
import img1 from './Images/img19.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style1.css';
import AOS from 'aos';

function Contact() {
  const { t, i18n } = useTranslation();
  const { language, toggleLanguage } = useLanguage();

  useEffect(() => {
    // Manually update translations when language changes
    i18n.changeLanguage(language)
      .catch(error => console.error("Error changing language:", error));
  }, [language, i18n]);

  const changeLanguage = (lng) => {
    toggleLanguage(lng);
  };

  AOS.init();

	return(
           <>
             
             <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
          <img src={img1} style={{ width: "100%", height: "550px" }} alt="Cinque Terre" />
          <div className="overlay">
  <div className="topleft">
      <h1 data-aos="fade-down">{t('தொடர்பு')}</h1>

      <h6 className="link"><Link to='/'>{t('முகப்பு')}</Link> {'>'} {t('தொடர்பு')}</h6>
  </div>
  </div>

        </div>


        <section id="contact" data-aos="fade-up" style={{paddingTop:"130px",paddingBottom:"130px",backgroundColor:"#feebff"}}>
    <div className="container w-75 ">
          <h1>{t('தொடர்பு')}</h1>
          <div className="row gy-5 text-center pt-5">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <p><span className="fa-solid fa-location-dot"></span></p>
              <h6 style={{fontWeight:"bold"}}>{t('முகவரி')}</h6>
              <p>{t('ராமசுப்ரமணியன் 184, தெற்கு ரத வீதி, தபசு மண்டபம் அருகில், சங்கரன் கோவில்-627756')},<br/>  {t('தென்காசி மாவட்டம்')}.</p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <p><span className="fa-solid fa-envelope"></span></p>
              <h6 style={{fontWeight:"bold"}}>{t('மின்னஞ்சல்')}</h6>
              <p>ramsubramanian2012@gmail.com</p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <p><span className="fa fa-phone me-1"></span></p>
              <h6 style={{fontWeight:"bold"}}>{t('தொலைபேசி')}</h6>
              <p>+91 9942559847<br/>
+91 7598942840</p>
              </div>
          </div>
    </div>

    </section>

    <section className="map container" data-aos="flip-left" style={{paddingBottom:"130px",paddingTop:"130px"}}>
          <div className="row">
               <div className="col-md-12">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31509.92650362266!2d77.49615501510505!3d9.177836731513768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0697eb07d96005%3A0x3d454d43a9559d3d!2sSankarankoil%2C%20Tamil%20Nadu%20627756!5e0!3m2!1sen!2sin!4v1701685209754!5m2!1sen!2sin"
      width="100%"
      height="450"
      style={{ border: '0' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade" title="Location map"
    ></iframe>
                   
               </div>
          </div>
    </section>

           
             

           </>
		)
}
export default Contact;