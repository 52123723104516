import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Carousel from './Carousel';
import About from './About';
import Contact from './Contact';
import Navbar1 from './Navbar1';
import Join from './Join';
import Events from './Events';
import Donation from './Donation';
import { LanguageProvider } from './LanguageContext';
import { ActiveLinkProvider } from './ActiveLinkContext';
import Footer from './Footer';
import History from './History';

function App() {
  return (
    <>
      <Router>
        <LanguageProvider>
          <ActiveLinkProvider>
            <Navbar1 />
            <Routes>
              <Route path="/" element={<Carousel />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="join" element={<Join />} />
              <Route path="events" element={<Events />} />
              <Route path="donation" element={<Donation />} />
              <Route path="history" element={<History />} />
            </Routes>
            <Footer />
          </ActiveLinkProvider>
        </LanguageProvider>
      </Router>
    </>
  );
}

export default App;
