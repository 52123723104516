import React from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import img1 from './Images/img16.jpg';
import './Style1.css';
function History(){

	 const { t } = useTranslation();

  return(
  	<>

  	<div className="container-fluid" style={{ margin: 0, padding: 0 }}>
          <img src={img1} style={{ width: "100%", height: "500px" }} alt="Cinque Terre" />
          <div className="overlay">
  <div className="topleft">
      <h1 data-aos="fade-down">{t('வரலாறு')}</h1>
            <h6 className="link"><Link to='/'>{t('முகப்பு')}</Link> {'>'} {t('வரலாறு')}</h6>
  </div>
  </div>

        </div><br/><br/><br/><br/>
  		<div className="container" style={{paddingBottom:"100px"}}>
  		<h1 className="text-center pb-5"  data-aos="fade-up">கணக்கம்பட்டி <span>சித்தரின்</span> வரலாறு</h1>
  			<p>கணக்கன்பட்டி சிவப் பிரபாகர சித்தயோகி என்ற பழனி சுவாமி சித்தரின் ரத்தினச் சுருக்க வரலாறு

ஸ்ரீ பாம்பாட்டி சித்தரின் மறு அவதாரம் தான் ஸ்ரீ கணக்கன்பட்டி சித்தர்

பாம்பாட்டி சித்தரின் சீடரான ஸ்ரீ சிவபிரபாகர சித்தயோகி 720 ஆண்டுகளுக்கு மேலாக ஒரே உடலில் வாழ்ந்தவர்கள்

இந்த சிவபிரபாகர சித்தயோகி வாழ்நாளில் முழுவதும் துளசி தண்ணீர் மட்டும் தான் குடித்து வந்தார்கள்</p>


<p>இந்த சிவபிரபாகர சித்தயோகி வாழ்நாளில் முழுவதும் துளசி தண்ணீர் மட்டும் தான் குடித்து வந்தார்கள்

யாராவது உணவு விரும்பி கொடுத்தால் மட்டும் உண்டு கொள்வார்கள்
பல்வேறு உடம்புகளில் கூடு விட்டு கூடு பாயும் கலையை கற்றுத் தெரிந்தவர்கள் அட்டமா சித்தி பெற்றவர்கள்

ஸ்ரீ சிவப்பிரபாகர சித்தயோகி  ஐயப்பனாகவும் இயேசுவாகவும் நபிகள் நாயகமாகவும்
சீரடி சாய்பாபாவும் பூண்டி மகானாகவும் பாண்டிச்சேரி சட்டி சுவாமிகள்
ராமலிங்க அடிகளார்அவதரித்தவர்கள் தான் இந்த சிவ
சிவப்பிரபாகர சித்தயோகி அவர்கள்</p>

<p>சங்கரன் கோயிலில் ஸ்ரீ கோமதி அம்பாளை பிரதிஷ்டை செய்ததும் ஸ்ரீ பாம்பாட்டி சித்தரும் அவருடைய சீடர்  சிவ பிரபாகர சித்தயோகி தான் 

ஸ்ரீ சிவ பிரபாகர சித்தயோகி அவர்கள் ஜீவசமாதி ஆகும்போது எனக்கு ஆறு வயது ஸ்ரீ சிவ பிரபாகர சித்த யோகியின் சீடர் சித்த ராஜசுவாமிகள் 

 ஸ்ரீ சிவ பிரபாகர சுவாமிகள் ஜீவ சமாதி ஆகும்போது  குழந்தை வடிவத்திற்கு வந்து விட்டார்கள் எல்லாருக்கும் கையக்காட்டியே சென்று உயிருடன் ஜீவசமாதி ஆனார்கள் நாங்கள் நேரடியாக நாங்கள் பார்த்த காட்சிகள் கேரளாவில் பத்தனம்திட்டம் அருகே ஓமலூர் என்ற கிராமத்தில் சாமியின் ஜீவ சமாதியும் உள்ளது. </p>
  		

<p>இந்த சிவப்ரபாத சித்தயோகி அவர்கள் தான் பழனி கணக்கன்பட்டி சாமி உடம்பிற்குல் கூடுவிட்டு கூடு பாய்ந்தார்கள்

எப்படி என்று இரத்தின சுருக்கமாக கூறுகின்றேன்

கணக்கன்பட்டி சுவாமிகள் ஒரு எளிமையான குடும்பத்தில் வாழ்ந்தவர்கள்
அவருடைய இயற்பெயர் காளிமுத்து

ஒரு தோட்டத்தில் வேலை பார்த்துக்  வந்தார்கள்.

 அருகில் உள்ள மற்றொரு தொட்டதுக்காரருக்கும் இவருடைய தோட்டத்து முதலாளிக்கும்  
பகை வந்தது விட்டது</p>


<p>இந்தப் பகையில் சாமியின் முதலாளியை அந்தத் தோட்டத்து கார ர்கள் அடித்து விட்டார்கள்

இதன் காரணமாக அந்தத் தோட்டத்துக்காரர்கள் சாமியை அடித்து பழனி மலை மேற்கு தொடர்ச்சி மலை அடிவாரத்தில் கொண்டு போய் விட்டார்கள் .
15 தினங்களுக்கு மேல் மழையில் இருக்கிறார்கள்

அது யாரோ ஒருவர் கண்டுபிடித்து பழனி கவர்மெண்ட் ஆஸ்பத்திரியில் சேர்த்து விட்டார்கள்.

டாக்டர்கள் பரிசோதித்த பிறகு சாமி இறந்து விட்டதாக  கூறி விட்டார்கள்.

நாளை உடல் கூறு பரிசோதனை நடைபெறும் என்று டாக்டர்கள் கூறிய நிலையில்.</p>


<p>சாமி அவர்கள் கட்டிலில் உட்கார்ந்து நிலையில் உள்ளார்கள்.

நாங்கள் முன்பு கூறியிருந்தபடி ஸ்ரீ சிவபிரபாகர சித்தயோகி அவர்கள்  சாமியின் உடலில் புகுந்து விட்டார்கள்.

ஆஸ்பத்திரியில் இருக்கும் நோயாளிகளுக்கு சாமி அவர்கள் டீ வாங்கி கொடுத்தால் நோய்கள் தீர்ந்துவிடும் போன்ற அதிசயங்களை செய்து வந்தார்கள் நம்முடைய சாமி அவர்கள்.

பின்பு கொஞ்ச காலமாக பழனியில் உள்ள தெருக்களை சுற்றி கொண்டு வந்தார்கள்

பின்பு பழனி ஆண்டவர் காலேஜில் சிறிது காலம் வாசலில் தங்கியிருந்தார்கள்.
</p>

<p>பின்பு காளி கோயிலில் தங்கியிருந்தார்கள்.

சாமியை முதல் முதலில் இந்த உலகத்திற்கு வெளியே கொண்டு வந்தவர்கள் சித்த ராஜ சுவாமிகள் தான்

இந்த சித்துராஜ் சுவாமிகளோடு தான் சங்கரன்கோவில் இருந்து என்னை முதன் முதலாக சாமியிடம் கூப்பிட்டு வந்தவர்கள் தான்.

சித்த ராஜ சுவாமிகள் என்னிடம் கூறும் பொழுது கணக்கன்பட்டி பழனிசாமிகள் முருகப்பெருமான் அவர்கள் தான் போகரும் அவர்தான் பாம்பாட்டி சித்தர் அவர் தான் என்று எங்களிடம் மறைமுகமாக கூறினார்கள்.</p>

 
<p>இன்னும் சொல்லப்போனால் கடவுளின் அவதாரமாக வாழ்ந்து காட்டியவர்கள் தான் பழனி கணக்கன்பட்டி சுவாமிகள் பழனி கணக்கன்பட்டி சுவாமிகள் இந்த உலகத்தில் எல்லா இடத்திலும் பரம்பொருளாக காட்சித் தந்து கொண்டுஇருக்கின்றார்கள்.

பழனி காளி கோவில் சுண்ணாம்பு காளவாசல் இருக்கும்போதுதான் அடியேன் சாமி கூட தங்கி இருந்த காலங்கள் பயணித்த காலங்கள் 35 ஆண்டுகள் காலமாக</p>


<p>சாமியின் வரலாறை நிறைய சொல்லலாம் இன்னொரு நாளில் நாங்கள் விளக்கமாக கூறிக் கொண்டு வருகிறோம்.</p>


<p>பழனி ஸ்ரீ சிவப்பிரபாகர சித்தயோகி என்ற  கணக்கன்பட்டி பழனிச்சாமியின்  மாணவன்
கணக்கம்பட்டியாரின் உலகத் திருக்கோயில்கள் அறக்கட்டளை நிறுவனர் <br/> ராமமூர்த்தி என்ற ராமசுப்ரமணியன் <br/> சங்கரன்கோவில் <br/>
<span style={{fontWeight:"bold"}} className="ms-2">9942559847</span></p>


 
  		</div>

  		</>
  	)
}
export default History;