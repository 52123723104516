import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import img1 from './Images/img6.jpg';
import AOS from 'aos';
import './Style1.css';


function Donation(){

  const { t } = useTranslation();

  AOS.init();


  return(
           <>
              <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
          <img src={img1} style={{ width: "100%", height: "500px" }} alt="Cinque Terre" />
          <div className="overlay">
  <div className="topleft">
      <h1 data-aos="fade-down">{t('நன்கொடை')}</h1>

      <h6 className="link"><Link to='/'>{t('முகப்பு')}</Link> {'>'} {t('நன்கொடை')}</h6>
  </div>
  </div>

        </div>

        <div className="parent">
      
        <div className="container-fluid background">
        <div data-aos="fade-down">
          <div  className="form-box text-center">
             <h1 className="text-center">{t('நன்கொடை')} <span>{t('கொடை')}</span></h1><br/>
                <p><span style={{fontWeight:"bold"}}>Account No:</span> 510909010228332</p>
                <p><span style={{fontWeight:"bold"}}>IFSC Code:</span> CIUB0000348</p>
                <p><span style={{fontWeight:"bold"}}>Sri Gomathi Amman Nattu Marundhu Kadai</span><br/><span  style={{fontWeight:"bold"}}>CITY UNION BANK , SANKARANKOVIL</span></p>
             
          </div>
        </div>
      </div>

        </div>

           </>
    )
}
export default Donation;