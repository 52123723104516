import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from './LanguageContext';
import img1 from './Images/img9.avif';
import img2 from './Images/img22.webp';
import './Style1.css';
import AOS from 'aos';



function About() {

  const { t, i18n } = useTranslation();
  const { language } = useLanguage();

  useEffect(() => {
    // Manually update translations when language changes
    i18n.changeLanguage(language)
      .then(() => console.log('Language changed successfully to:', language))
      .catch(error => console.error("Error changing language:", error));
  }, [language, i18n]); // Include i18n in the dependency array

  AOS.init();

  return (
    <>
      
        <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
          <img src={img1} style={{ width: "100%", height: "500px" }} alt="Cinque Terre" />
          <div className="overlay">
  <div className="topleft">
      <h1 data-aos="fade-down">{t('பற்றி')}</h1>
            <h6 className="link"><Link to='/'>{t('முகப்பு')}</Link> {'>'} {t('பற்றி')}</h6>
  </div>
  </div>

        </div><br/><br/><br/><br/>

        <div className="container" style={{paddingBottom:"100px"}}>
              <div className="row">
              <h1 className="text-center pb-5" data-aos="fade-up">{t('பற்றி')}</h1>
                    {/*<div className="col-lg-1"></div>
                   <div className="col-sm-12 col-md-12 col-lg-5 col-12 pt-5">
                   <div className="box p-3" data-aos="zoom-in">
                        <img src={img2} alt="Cinque Terre" className="img-fluid" style={{height:"300px"}}/>
                        </div>
                   </div>

                  

                   <div className="col-sm-12 col-md-12 col-lg-6 col-12 para col-12 p-3 about" data-aos="zoom-in">
                         
                        <br/><br/><br/>
                        
   <p>
  {t('நவீன கண்டுபிடிப்புகளுடன் பழங்கால மரபுகள் செழித்து வளரும் உலகத்தை நாங்கள் கற்பனை செய்கிறோம், நிலையான விவசாய நடைமுறைகள் சமூகங்களை ஆதரிக்கின்றன, மேலும் நமது ஆறுகள் தூய்மையாகவும் ஏராளமாகவும் பாய்கின்றன.')}
</p>
<h4>{t('எங்கள் நோக்கம்')}:</h4>
<p>
  {t('வரலாற்று சிறப்புமிக்க கோவில்களை மறுசீரமைத்தல் மற்றும் அவற்றை பாதுகாத்தல், பசுமையான மற்றும் வளமான எதிர்காலத்திற்காக நமது நீர்நிலைகளை தூய்மைப் படுத்துதல் மற்றும் அவற்றிற்கு புத்துயிர் அளித்தல்.')}
</p>
                   </div>*/}

<p>{t('ஸ்ரீ பாம்பாட்டி சித்தரின் மறு அவதாரம் பழனி சிவ பிரபாகர சித்தயோகி என்ற கணக்கன்பட்டி சித்தர் சுவாமிகளின் ஆணையின்படி. வருங்காலத்தில் தண்ணீர் பஞ்சத்தை போக்குவதற்கு ஒன்பது கோடி மரங்கள் நட வேண்டும் என்ற நோக்கத்துடன் நட்டிக் கொண்டு வருகிறோம்.')}</p>

<p>{t('பழமையான சிவாலயங்கள் பெருமாள் கோயில்கள் ஜீவசமாதிகள் உழவாரப் பணி செய்து பராமரித்து வருகிறோம் நிலத்தடி நீர்மட்டம் உயர ஊரணிகள் குளங்கள் தெப்பங்கள் ஆறுகள் சுத்தப்படுத்தி தூர்வாரியும் ஆழப்படுத்தியும் ஏற்பாடு செய்து வருகிறோம்.')}</p>

<p>{t('பகவானின் ஆணைப்படி முதியோர் இல்லம் கல்விக்கூடங்கள் தர்மசாலை அமைப்பதற்கு இடங்களே தேர்வு செய்து கொண்டிருக்கிறோம். பழமையான சிவாலயங்கள் எங்கெல்லாம் தரையில் இருக்கின்றனவோ அதை  பிரதிஷ்டை செய்து கொண்டு வருகிறோம்.')}</p>

<p>{t('நாம் வாழும் காலத்தில் ஒருவேளை கூட உணவு இல்லாதவர்களுக்கு மூன்று வேலை உணவு கொடுப்பதற்கு நம்முடைய அறக்கட்டளை மூலம் ஏற்பாடு செய்து கொண்டு வருகிறோம். அனைத்து மாவட்டங்களில் பகவானின் தியானக் கூடங்கள் அமைப்பதற்கு அனைத்து மாவட்டங்களிலும் ஏற்பாடுகள் தேர்வு செய்து கொண்டு வருகிறோம்')}</p>


<p>{t('பள்ளிப்படிப்பு தொடர முடியாத மாணவர்களுக்கு பாட புத்தகங்கள் சீருடைகள் வழங்க ஏற்பாடு கொண்டு வருகிறோம். தமிழ்நாட்டில் முக்கிய கோயில்களில் திருவிழா காலங்களில். பக்தர்கள் தங்குவதற்கும் அன்னதானம் வழங்குவதற்கும் ஏற்பாடு செய்து வருகிறோம்.')}</p>


<p>{t('தமிழ்நாடு முழுவதும் மாதம் ஒரு முறை கண் அறுவை சிகிச்சை முகாம் நடத்த ஏற்பாடு செய்திருக்கிறோம் கோசாலை அமைப்பதற்கு ஏற்பாடு  இடங்களை தேர்வு செய்து இருக்கிறோம் சாமியின் உத்தரவுப்படி இன்னும் நிறைய சமூக சேவை செய்ய இருக்கிறோம்')}</p>

<p>{t('நம்முடைய கணக்கம்பட்டியார் உலக திருக்கோயில்கள் அறக்கட்டளை  உலகம் முழுவதும் தொடங்க இருக்கிறது')}</p>

<p>{t('கணக்கம்பட்டியார் உலக திரு கோயில் அறக்கட்டளை நிறுவனர்')}</p>
<p>{t('க.இராமசுப்பிரமணியன்')}</p> 
<p>{t('சங்கரன்கோவில்')}</p>
<p>9942559847</p>

              </div>
        </div>
      
    </>
  );
}

export default About;
