import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import img1 from './Images/img4.jpg';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './Style1.css';




const Join = () => {

  const { t } = useTranslation();




  const [data, setFormData] = useState({
    name: '',
    fname: '',
    sname: '',
    dob: '',
    gender: '',
    email: '',
    inputAddress: '',
    roles: '',
    inputCountry: 'India',
    countryName: '',
    inputState: '',
    inputCity: '',
    stateName: '',
    cityName: '',
    tel: '',
    Whatsapp: '',
    bldgrp: 'A+',
    pic: '',
    info: '',
  });



  const [isFormSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);
  const [errors, setErrors] = useState({});
  //const formRef = useRef(null);






  const isValidEmail = (email) => {
    // Regular expression for a permissive email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };


  const isValidWhatsapp = (whatsapp) => {
    // Regular expression for a simple WhatsApp number validation
    // Assuming WhatsApp numbers start with a plus sign and contain only digits
    const whatsappRegex = /^\+[0-9]+$/;
    return whatsappRegex.test(whatsapp);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate required fields (fields with asterisk *)
    if (data.name.trim() === '') {
      newErrors.name = 'Name is required';
    } else if (!/^[A-Za-z\u0B80-\u0BFF\s]+$/.test(data.name)) {
      newErrors.name = 'Name is in the wrong format';
    }

    if (data.fname.trim() === '') {
      newErrors.fname = 'Father Name is required';
    } else if (!/^[A-Za-z\u0B80-\u0BFF\s]+$/.test(data.fname)) {
      newErrors.fname = 'Father Name is in the wrong format';
    }

    if (data.sname.trim() !== '' && !/^[A-Za-z\u0B80-\u0BFF\s]+$/.test(data.sname)) {
      newErrors.sname = 'Spouse Name is in the wrong format';
    }

    if (data.dob.trim() === '') {
      newErrors.dob = 'Date of Birth is required';
    }
    if (data.inputAddress.trim() === '') {
      newErrors.inputAddress = 'Address is required';
    }
    if (data.roles.trim() === '') {
      newErrors.roles = 'Please select a Roles';
    }
    if (data.Whatsapp.trim() === '') {
      newErrors.Whatsapp = 'Whatsapp Number is required';
    }
    if (!data.pic) {
      newErrors.pic = 'Please upload a photo';
    }

    if (data.bldgrp === 'Choose...') {
      newErrors.bldgrp = 'Please select a Blood Group';
    }
    if (data.gender.trim() === '') {
      newErrors.gender = 'Gender is required';
    }
    if (data.email.trim() !== '' && !isValidEmail(data.email)) {
      newErrors.email = 'Email is not in the correct format';
    }
    if (data.Whatsapp.trim() !== '' && !isValidWhatsapp(data.Whatsapp)) {
      newErrors.Whatsapp = 'Whatsapp is not in the correct format';
    }




    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };




  const initialState = {
    name: '',
    fname: '',
    sname: '',
    dob: '',
    gender: '',
    email: '',
    inputAddress: '',
    roles: '',
    inputCountry: '',
    countryName: '',
    inputState: '',
    inputCity: '',
    stateName: '',
    cityName: '',
    tel: '',
    Whatsapp: '',
    bldgrp: 'A+',
    pic: null,
    info: '',
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024) { // 1MB
      alert('Uploaded file must be less than 1MB');
      return;
    }
    setFormData({ ...data, pic: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("fname", data.fname);
      formData.append("sname", data.sname);
      formData.append("dob", data.dob);
      formData.append("gender", data.gender);
      formData.append("email", data.email);
      formData.append("inputAddress", data.inputAddress);
      formData.append("roles", data.roles);
      formData.append("inputCountry", data.countryName);
      formData.append("inputState", data.stateName);
      formData.append("inputCity", data.cityName);
      formData.append("tel", data.tel);
      formData.append("Whatsapp", data.Whatsapp);
      formData.append("bldgrp", data.bldgrp);
      formData.append("pic", data.pic);
      formData.append("info", data.info);

      try {
        const response = await axios.post('https://kanakkampattiyar-arakkattalai.com/api/submit-form', formData, {
          responseType: 'blob',
          withCredentials: true,
        });

        console.log(response.data);

        setFormSubmittedSuccessfully(true);
        setFormData(initialState);

        setTimeout(() => {
          setFormSubmittedSuccessfully(false);
        }, 5000);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${data.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

      } catch (error) {
        console.error(error);
      }
    }
  };





  return (
    <>
      <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
        <img src={img1} style={{ width: "100%", height: "500px" }} alt="Cinque Terre" />
        <div className="overlay">
          <div className="topleft">
            <h1>{t('சேருங்கள்')}</h1>

            <h6 className="link"><Link to='/'>{t('முகப்பு')}</Link> {'>'}  {t('சேருங்கள்')}</h6>
          </div>
        </div>

      </div>

      <div className="form" style={{ paddingTop: "150px", paddingBottom: "150px", backgroundColor: "white" }}>
        <div className="container-fluid" data-aos="flip-right" >
          <div className="contact">
            <h1 className="text-center">{t('எங்கள் பக்கத்திற்கு வரவேற்கிறோம்')}</h1>
            <h6 className="text-center">{t('உள்ள இடத்தை கண்டிப்பாக நிரப்பவும்')}</h6><br /><br />
            <section className="container my-2 p-2">

              {/* Display success message if the form was submitted successfully */}

              {isFormSubmittedSuccessfully && (
                <div className="alert alert-success alert-dismissible text-center mt-3" role="alert">
                  <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                  <strong>Form submitted successfully!</strong>
                </div>
              )}


              {/* Display general error message here */}
              {Object.keys(errors).length > 0 && (
                <div className="alert alert-danger">
                  Fields cannot be empty.
                </div>
              )}
              <form className="join row g-3" onSubmit={handleSubmit} autoComplete='off'>
                {/* Full Name */}
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    <span>*</span> {t('பெயர்')}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.name && 'is-invalid'}`}
                    id="name"
                    name="name" value={data.name}
                    onChange={(e) => setFormData({ ...data, name: e.target.value })}
                    placeholder="Enter your name"

                  />
                  {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                </div>
                {/* Father Name */}
                <div className="col-md-6">
                  <label htmlFor="fname" className="form-label">
                    <span>*</span> {t('தந்தை பெயர்')}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.fname && 'is-invalid'}`}
                    id="fname"
                    name="fname" value={data.fname}
                    onChange={(e) => setFormData({ ...data, fname: e.target.value })}
                    placeholder="Enter your father name"

                  />
                  {errors.fname && <div className="invalid-feedback">{errors.fname}</div>}
                </div>
                {/* Spouse Name */}
                <div className="col-md-6">
                  <label htmlFor="sname" className="form-label">
                    {t('கணவன்/மனைவி பெயர்')}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.sname && 'is-invalid'}`}
                    id="sname"
                    name="sname"
                    value={data.sname}
                    onChange={(e) => setFormData({ ...data, sname: e.target.value })}
                    placeholder="Enter your spouse name"
                  />
                  {errors.sname && <div className="invalid-feedback">{errors.sname}</div>}
                </div>
                {/* Date of Birth */}
                <div className="col-md-6">
                  <label htmlFor="dob" className="form-label">
                    <span>*</span>  {t('பிறந்த தேதி')}
                  </label>
                  <input
                    type="date"
                    className={`form-control ${errors.dob && 'is-invalid'}`}
                    id="dob"
                    name="dob" value={data.dob}
                    onChange={(e) => setFormData({ ...data, dob: e.target.value })}

                  />
                  {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                </div>
                {/* Gender */}
                <div className="col-md-6">
                  <label className="form-label">
                    <span>*</span>  {t('பாலினம்')}
                  </label><br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="male1"
                      value="Male"
                      onChange={(e) => setFormData({ ...data, gender: e.target.value })}
                      checked={data.gender === 'Male'}
                    />
                    <label className="form-check-label" htmlFor="male1">
                      {t('ஆண்')}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="female2"
                      value="Female"
                      onChange={(e) => setFormData({ ...data, gender: e.target.value })}
                      checked={data.gender === 'Female'}
                    />
                    <label className="form-check-label" htmlFor="female2">
                      {t('பெண்')}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="transgender3"
                      value="Transgender"
                      onChange={(e) => setFormData({ ...data, gender: e.target.value })}
                      checked={data.gender === 'Transgender'}
                    />
                    <label className="form-check-label" htmlFor="transgender3">
                      {t('மாற்றுபாலினத்தவர்')}
                    </label>
                  </div>
                  {errors.gender && (
                    <div className="text-danger">
                      {errors.gender}
                    </div>
                  )}
                </div>
                {/* Email */}
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    {t('மின்னஞ்சல்')}
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    name="email" value={data.email}
                    onChange={(e) => setFormData({ ...data, email: e.target.value })}
                    placeholder="Enter your email"
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>

                {/* country below for country, state and city i added key like this key={isFormSubmittedSuccessfully ? 'reset-country' : 'country'} 
       to clear the input fields after form is submitted */}
                <div className="col-md-4">
                  <label htmlFor="inputCountry" className="form-label">
                    {t('நாடு')}
                  </label>
                  <CountrySelect
                    key={isFormSubmittedSuccessfully ? 'reset-country' : 'country'}
                    value={{ id: data.inputCountry, name: data.countryName }}
                    name="inputCountry"
                    className="form-control"
                    id="inputCountry"
                    onChange={(e) => {
                      setFormData({ ...data, inputCountry: e.id, countryName: e.name });
                    }}
                  />
                </div>

                {/* State */}
                <div className="col-md-4">
                  <label htmlFor="inputState" className="form-label">
                    {t('மாநிலம்')}
                  </label>
                  <StateSelect
                    key={isFormSubmittedSuccessfully ? 'reset-state' : 'state'}
                    countryid={data.inputCountry}
                    value={{ id: data.inputState, name: data.stateName }}
                    name="inputState"
                    id="inputState"
                    className="form-select"
                    onChange={(e) => {
                      setFormData({ ...data, inputState: e.id, stateName: e.name });
                    }}
                  />
                </div>

                {/* City */}
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="form-label">
                    {t('மாவட்டம்')}
                  </label>
                  <CitySelect
                    key={isFormSubmittedSuccessfully ? 'reset-city' : 'city'}
                    className="form-select"
                    id="inputCity"
                    name="inputCity"
                    value={{ id: data.inputCity, name: data.cityName }}
                    countryid={data.inputCountry}
                    stateid={data.inputState}
                    onChange={(e) => {
                      setFormData({ ...data, inputCity: e.id, cityName: e.name });
                    }}
                  />
                </div>

                {/* Address */}
                <div className="col-md-6">
                  <label htmlFor="inputAddress" className="form-label">
                    <span>*</span>  {t('முகவரி')}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.inputAddress && 'is-invalid'}`}
                    id="inputAddress"
                    name="inputAddress" value={data.inputAddress}
                    onChange={(e) => setFormData({ ...data, inputAddress: e.target.value })}
                    placeholder="Enter street address"

                  />
                  {errors.inputAddress && <div className="invalid-feedback">{errors.inputAddress}</div>}
                </div>

                {/* Roles */}
                <div className='col-md-6'>
                  <label htmlFor='roles'>  <span>*</span>  {t('பாத்திரங்கள்')}</label>
                  <select
                    className={`form-select ${errors.roles && 'is-invalid'}`}
                    id='roles'
                    name='roles' value={data.roles}
                    onChange={(e) => setFormData({ ...data, roles: e.target.value })}
                  >
                    <option value="தலைவர்">தலைவர்</option>
                    <option value="துணை ஜனாதிபதி">துணை ஜனாதிபதி</option>
                    <option value="செயலாளர்">செயலாளர்</option>
                    <option value="உதவி செயலாளர்">உதவி செயலாளர்</option>
                    <option value="குழு தலைவர்கள்">குழு தலைவர்கள்</option>
                    <option value="பொது உறுப்பினர்கள்">பொது உறுப்பினர்கள்</option>
                    <option value="நிகழ்ச்சி ஒருங்கிணைப்பாளர்">நிகழ்ச்சி ஒருங்கிணைப்பாளர்</option>
                  </select>

                  {errors.roles && <div className='invalid-feedback'>{errors.roles}</div>}

                </div>

                {/* Phone Number */}
                <div className="col-md-6">
                  <label htmlFor="tel" className="form-label">
                    {t('தொலைபேசி எண்')}
                  </label>
                  <PhoneInput
                    international
                    defaultCountry="IN" // Set your desired default country code
                    className="form-control"
                    name="tel"
                    id="tel" value={data.tel}
                    onChange={(value) => setFormData({ ...data, tel: value })}
                    placeholder="Phone number:"
                  />

                </div>
                {/* Whatsapp Number */}
                <div className="col-md-6">
                  <label htmlFor="Whatsapp" className="form-label">
                    <span>*</span> {t('whatsapp எண்')}
                  </label>

                  <PhoneInput
                    international
                    defaultCountry="IN" // Set your desired default country code
                    className={`form-control ${errors.Whatsapp && 'is-invalid'}`}
                    name="Whatsapp"
                    id="Whatsapp" value={data.Whatsapp}
                    onChange={(value) => setFormData({ ...data, Whatsapp: value })}
                    placeholder="Whatsapp number:"
                  />

                  {errors.Whatsapp && <div className="invalid-feedback">{errors.Whatsapp}</div>}
                </div>
                {/* Blood Group */}
                <div className="col-md-6">
                  <label htmlFor="bldgrp" className="form-label">
                    {t('இரத்த வகை')}
                  </label>
                  <select
                    className="form-select"
                    id="bldgrp"
                    name="bldgrp" value={data.bldgrp}
                    onChange={(e) => setFormData({ ...data, bldgrp: e.target.value })}
                  >
                    <option value="A+">A+</option>
                    <option value="B+">B+</option>
                    <option value="O+">O+</option>
                    <option value="AB+">AB+</option>
                    <option value="A-">A-</option>
                    <option value="B-">B-</option>
                    <option value="O-">O-</option>
                    <option value="AB-">AB-</option>
                  </select>
                </div>
                {/* Member Photo */}
                <div className="col-md-6">
                  <label htmlFor="pic" className="form-label">
                    <span>*</span>  {t('உறுப்பினர் புகைப்படம்')}
                  </label>
                  <input
                    type="file"
                    name="pic"
                    id="pic"
                    className={`form-control ${errors.pic && 'is-invalid'}`}
                    onChange={handleFileChange}
                  />
                  {errors.pic && <div className="invalid-feedback">{errors.pic}</div>}
                </div>
                {/* Additional Info */}
                <div className="col-12">
                  <label htmlFor="info" className="form-label">
                    {t('கூடுதல் தகவல்')}
                  </label>
                  <textarea
                    className="form-control"
                    rows="5"
                    id="info"
                    name="info" value={data.info}
                    onChange={(e) => setFormData({ ...data, info: e.target.value })}
                  />
                </div>
                <div className="col-12 text-center pt-5">
                  <button type="submit" className="submit" style={{
                    width: "200px", fontSize: "18px", border: "none", backgroundImage: "linear-gradient(135deg, #CB356B 10%, #BD3F32 100%)", padding: "16px 20px",
                    transition: "0.2s", borderRadius: "5px", color: "white", fontWeight: "bold"
                  }}>
                    Submit
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>

    </>
  );
};

export default Join;
