import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from './LanguageContext';
import img1 from './Images/Robinson_Creek_before.jpg';
import img2 from './Images/Srirangam_annadhanam_22.jpg';
import img3 from './Images/img26.webp';
import img4 from './Images/img20.jpg';
import img5 from './Images/img47.jpeg';
import img6 from './Images/img48.jpeg';
import video from './Images/video2.mp4';
import AOS from 'aos';
import './Style1.css';

function Events() {

  AOS.init();

  const { t, i18n } = useTranslation();
  const { language, toggleLanguage } = useLanguage();

  useEffect(() => {
    // Manually update translations when language changes
    i18n.changeLanguage(language)
      .catch(error => console.error("Error changing language:", error));
  }, [language, i18n]);

  const changeLanguage = (lng) => {
    toggleLanguage(lng);
  };

  const videoRef = React.useRef(null);

  const handleVideoError = () => {
    console.error("Error occurred while loading or playing the video.");
  };


  return (
  	<>
     <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
          <img src={img4} style={{ width: "100%", height: "500px" }} alt="Cinque Terre" />
          <div className="overlay">
  <div className="topleft">
      <h1 data-aos="fade-down">{t('நிகழ்வுகள்')}</h1>

      <h6 className="link"><Link to='/'>{t('முகப்பு')}</Link> {'>'} {t('நிகழ்வுகள்')}</h6>
  </div>
  </div>

        </div>

<section>
    <div className="container mt-3 ">
  
    <h1 className="text-center" data-aos="fade-down">{t('நாம்')} <span>{t('என்ன')}</span> {t('செய்கிறோம்')}</h1><br/>

   

        <div className="row pt-5 gy-5 pomplate">
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 me-5 pe-5">
            <img src={img5} className="pomplateimg" alt="pomplateimg"/>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-12 ms-5 ps-5">
            <img src={img6} className="pomplateimg1" alt="pomplateimg1"/>
          </div>
        </div>

        <div className="container-fluid" style={{paddingTop:"130px",paddingBottom:"100px"}}>
            <video  
        loop 
        controls
        ref={videoRef}
        onError={handleVideoError} style={{border:"10px solid black"}} className="video"
      >
        <source src={video} type="video/mp4"/>
        <source src={video} type="video/ogg"/>
        Your browser does not support the video tag.
      </video>
        </div>


      <div className="row gy-5 pt-5">
        <div className="col-sm-12 col-md-12 col-lg-4 col-12" data-aos="zoom-in">
          <div className="card card2">
          <div className="inner inner1">
            <img
              className="card-img-top img-fluid"
              src={img3}
              alt=""
              style={{ width: '100%', height:"340px" }}
            />
            </div>
            <div className="card-body">
              <h4 className="card-title">புராதான கோவில்களை சீரமைத்தல்</h4>
              <p className="card-text">
               பாழடைந்த கட்டிடங்களை சீரமைத்தல், கோவிலை துடைத்து சுத்தம் செய்தல், வழிபாட்டிற்கு செல்லும் பாதைகளை சுத்தம் செய்தல், விளக்கு ஏற்றுதல், தோட்டம் அமைத்தல், மாலை அணிவித்தல், பூஜை பாத்திரங்களை மெருகூட்டுதல், குடிநீர் மற்றும் தங்குமிடம் வழங்குதல் போன்ற கூட்டு நடவடிக்கைகள் செய்தல்
              </p>
              
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-12" data-aos="zoom-in">
          <div className="card card3">
          <div className="inner inner2">
            <img
              className="card-img-top"
              src={img2}
              alt=""
              style={{ width: '100%',height:"340px" }}
            />
            </div>
            <div className="card-body">
              <h4 className="card-title">அன்னதானம்</h4>
              <p className="card-text">
                ‘தானம்’ என்னும் வார்த்தையே மிகச்சிறந்த வார்த்தையாக இருக்கிறது. நாம், நமக்கு என்று சுயநலமாக இல்லாமல் பிறருக்கு, பிறருக்காக என்று மற்றவர்களை பற்றிய சிந்தனை நமக்கு எப்போது வருகிறதோ! அப்போதே நாம் பிறந்ததன் பலனை அடைந்து விட்டோம் என்பது தான் பொருள்.அத்தகைய தானத்தினை வழங்கி இறைவனை அடைவதற்கான தொண்டினை செய்தல்
              </p>
              
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-12" data-aos="zoom-in">
          <div className="card card1">
          <div className="inner">
            <img
              className="card-img-top"
              src={img1}
              alt=""
              style={{ width: '100%' }}
            />
            </div>
            <div className="card-body">
              <h4 className="card-title">ஆறுகள் மற்றும் குளங்களை தூய்மைப்படுத்துதல்</h4>
              <p className="card-text">
               புனித ஆறு மற்றும் குளங்களை தூய்மைப்படுத்தி இந்த உலகில் வாழும் ஜீவராசிகளுக்கான நீர் மேலாண்மையை மேம்படுத்தி இறைவனை அடைவதற்கான உழவாரப் பணிகளை முழுமையாக செய்தல்
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
   
    </>
  );
}

export default Events;
 