import React, { useEffect, useRef  } from 'react';
import img4 from './Images/img45.jpeg';
import img5 from './Images/img28.jpg';
import img6 from './Images/img29.jpg';
import img7 from './Images/img30.jpg';
import img8 from './Images/img31.jpg';
import img9 from './Images/img32.jpg';
import img10 from './Images/img34.jpg';
import img11 from './Images/img46.jpg';
import audioFile from './Images/audio.mp3'; 
import AOS from 'aos';
import './Style1.css';

import { useTranslation } from 'react-i18next';

function Carousel(){

   const { t } = useTranslation();

   const audioRef = useRef(null);

   useEffect(() => {
    const playAudio = () => {
      if (audioRef.current) {
        audioRef.current.play().catch(error => {
          console.error('Failed to play audio:', error);
        });
      }
    };

    const handleUserInteraction = () => {
      playAudio();
      // Remove event listeners after the first interaction
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };

    // Add event listeners for user interaction
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);

    return () => {
      // Clean up event listeners
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []); // Empty dependency array to run the effect only once when the component mounts

  AOS.init();
 

	return(
           <>

        
 
           <div className='container-fluid' style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={img4} className='image' />
</div>

           <audio ref={audioRef} src={audioFile} controls />
       

{/* About section starts*/}
<section style={{backgroundColor:"#feebff"}}>
<div className="container">
<div className="row about gy-3 gx-5">
<div className="col-12 col-md-12 col-sm-12 col-lg-6" data-aos="flip-right">
<h1 className="abt">{t('கணக்கம்பட்டியார் அறக்கட்டளைக்கு')} <span>{t('தங்களை வரவேற்கிறோம்')}</span> – 
 {t('பாரம்பரியத்தை பாதுகாத்தல், உயிர்களை வளர்ப்பது')}</h1>
<p style={{marginTop:"35px"}} className="para tamil-text">{t('கணக்கம்பட்டியார் அறக்கட்டளை, நமது கலாச்சார பாரம்பரியத்தை மீட்டெடுப்பதிலும், சமூக நலனை வளர்ப்பதிலும் நாங்கள் ஆர்வமாக உள்ளோம். பழமையான கோவில்களை புனரமைப்பது, ஆறுகள் மற்றும் ஏரிகளை புனரமைப்பது, அன்னதானம் அளிப்பது போன்றவற்றில் எங்களது அர்ப்பணிப்பு உள்ளது.')}</p>
</div>
<div className="col-lg-1"></div>
<div className="col-12 col-md-12 col-sm-12 col-lg-5" data-aos="flip-left">
<img src={img11} style={{height:"400px"}} className="lord img-fluid" alt=""/>
</div>
</div>
</div>
</section>

{/* Gallery starts here */}
<section className="gallery">
<div className="container-fluid">
<h1 className="text-center" data-aos="fade-up"><span>{t('நமது செயல்பாட்டின்')}</span> {t('புகைப்படங்கள்')}</h1>

<div className="container pics" data-aos="zoom-in">
<div className="row gallery gy-5">
<div className="col-12 col-md-12 col-sm-12 col-lg-4">
<img src={img5} className="images pic1" alt=""/>
</div>
<div className="col-12 col-md-12 col-sm-12 col-lg-4">
<img src={img6} className="images2 pic3" alt=""/>
</div>
<div className="col-12 col-md-12 col-sm-12 col-lg-4">
<img src={img7}  className=" pic1 images3" alt=""/>
</div>
</div>

<div className="row">
<div className="col-12 col-md-12 col-sm-12 col-lg-4">
<img src={img8}  className="images images1 pic2" alt=""/>
</div>
<div className="col-12 col-md-12 col-sm-12 col-lg-4">
<img src={img9} style={{marginTop:"40px"}} className="images2 pic4" alt=""/>
</div>
<div className="col-12 col-md-12 col-sm-12 col-lg-4">
<img src={img10} className="images1 pic2 images3" alt=""/>
</div>
</div>

</div>
</div>
</section>

           </>
		)
}
export default Carousel;