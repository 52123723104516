import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './Style1.css';
import { useTranslation } from 'react-i18next';
import { useActiveLink } from './ActiveLinkContext'; // Import the context hook

function Footer() {
  const { t } = useTranslation();
   const { activeLink, setActiveLinkGlobal } = useActiveLink(); // Access the active link state and update function from the context

    const handleFooterLinkClick = (link) => {
    setActiveLinkGlobal(link);
  };

  return (
    <>
      <div className="footer text-dark pt-5" style={{ borderTop: "10px solid #cd2f50", backgroundColor: "#101010" }}>
        <div className="space container">
          <div className="row">
            <div className="section col-md-6 col-sm-12 col-lg-5 col-12 d-flex flex-column justify-content-center">
              <h4>{t('பற்றி')}</h4><br />
              <p style={{ width: "350px" }}>
                {t('பாரம்பரிய பாதுகாப்பு, கோயில் மறுசீரமைப்பு மற்றும் சுற்றுச்சூழல் மறுமலர்ச்சி ஆகியவற்றில் நாங்கள் அர்ப்பணித்துள்ளோம்.')}
              </p>

              <div className="follow-us-container">
  <h4 className="pt-2">{t('எங்களை பின்தொடரவும்')}</h4>
  <div className="social-links">
    <p><b>Youtube : <a className="me-3 ms-2" href="https://youtube.com/@ramasubramaniang3767?si=1Gq5C7GTieii8vrS" target="_blank" rel="noopener noreferrer" style={{cursor:"pointer"}}><i className="fab fa-youtube" style={{color:"#cf2d50",fontSize:"20px"}}></i></a></b></p>
    <p><b>Instagram : <a href="https://www.instagram.com/ramasubramanian.g.3?igsh=ZW1sdGMwd3VxMWhq" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" style={{color:"#cf2d50",fontSize:"20px"}}></i></a></b></p>
  </div>
</div>
</div>

            


               <div className="col-md-6 col-sm-12 col-lg-4 col-12 d-flex mb-4 quick flex-column justify-content-center">
            <h4>{t('விரைவு இணைப்புகள்')}</h4><br />
            <p>
              <a className={`nav-link text-white ${activeLink === '/' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/" onClick={() => handleFooterLinkClick('/')} className="links">{t('முகப்பு')}</Link></a>
            </p>
            <p>
              <a className={`nav-link text-white ${activeLink === '/history' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/history" onClick={() => handleFooterLinkClick('/history')} className="links">{t('வரலாறு')}</Link></a>
            </p>
            <p>
              <a className={`nav-link text-white ${activeLink === '/about' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/about" onClick={() => handleFooterLinkClick('/about')} className="links">{t('பற்றி')}</Link></a>
            </p>
            <p>
              <a className={`nav-link text-white ${activeLink === '/contact' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/contact" onClick={() => handleFooterLinkClick('/contact')} className="links">{t('தொடர்பு')}</Link></a>
            </p>
            <p>
              <a className={`nav-link text-white ${activeLink === '/join' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/join" onClick={() => handleFooterLinkClick('/join')} className="links">{t('சேருங்கள்')}</Link></a>
            </p>
            <p>
              <a className={`nav-link text-white ${activeLink === '/events' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/events" onClick={() => handleFooterLinkClick('/events')} className="links">{t('நிகழ்வுகள்')}</Link></a>
            </p>
           <p>
              <a className={`nav-link text-white ${activeLink === '/donation' ? 'active' : ''}`} href="/"><i className="fa-solid fa-share me-1"></i><Link to="/donation" onClick={() => handleFooterLinkClick('/donation')} className="links">{t('நன்கொடை')}</Link></a>
            </p>
          </div>

            <div className="col-md-6 col-sm-12 address col-lg-3 col-12">
              <h4>{t('தொடர்பு கொள்ளுங்கள்')}</h4><br />
              <p>{t('கணக்கம்பட்டியார் உலக திருக்கோயில்கள் அறக்கட்டளை நிறுவனர்')}</p>
              <p><span className="fa fa-map-marker me-1"></span>{t('ராமசுப்ரமணியன் SMP')},<br /> {t('184, தெற்கு ரத வீதி')},<br /> {t('தபசு மண்டபம் அருகில்')}, <br />{t('சங்கரன் கோவில்-627756')}, <br />{t('தென்காசி மாவட்டம்')}. </p>
              <p><span className="fa fa-phone me-1"></span>9942559847</p>
              <p><span className="fa fa-phone me-1"></span>7598942840</p>
              <p><span className="fa fa-paper-plane me-1"></span><a href="/" className="mail">ramsubramanian2012@gmail.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#000", padding: "20px" }}>
        <p style={{ color: "white", textAlign: "center" }}>© 2024 Copyright :
          <a href="#" style={{ textDecoration: "none", color: "#cd2f50", marginLeft: "5px" }}>
            Brighton Cloud Technologies
          </a>
        </p>
      </div>
    </>
  );
}

export default Footer;




