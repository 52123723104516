import React from 'react';
import './Style1.css';
import { useLanguage } from './LanguageContext';

function LanguageSwitcher() {
  const { toggleLanguage } = useLanguage();

  return (
    <div className="language-switcher">
      <button
        className="switcher"
        style={{ backgroundColor: "white", color: "#cd2f50", border: "1px solid #cd2f50", padding: "5px 10px", borderRadius: "5px" }}
        onClick={() => toggleLanguage('en')} // Toggle to English
      >
        English
      </button>
      <button
        className="switcher"
        style={{ backgroundColor: "#cd2f50", color: "white", padding: "5px 10px", borderRadius: "5px", border: "1px solid white" }}
        onClick={() => toggleLanguage('ta')} // Toggle to Tamil
      >
        தமிழ்
      </button>
    </div>
  );
}

export default LanguageSwitcher;
